import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from '../../components/Container';
import Main from '../../layouts/Main';

const PreSchool = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
        <Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box>
              <Typography fontWeight={700} variant={'h4'} gutterBottom>
                Pre-School Tuition
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ marginY: 4 }} />
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={8}>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Description
                </Typography>
                <Typography component={'p'}>
                  Our kindergarten tuition programmes focus on building a strong foundation in languages and mathematics for the young kids. Guided by experienced teachers with professional teaching skills, we create a conducive learning environment and a fun and interesting surrounding for better learning. Your child will grow healthily and learn to better express himself with great confidence. (Tuition @Bedok North)
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                  {[
                    'Our enrollment process is simple and requires only basic information about your child.',
                    'We provide foundational literacy and numeracy programs guided by experienced teachers.',
                    'Engage your child with fun activities like storytelling and games for better participation.',
                    'Our enrollment process is simple and requires only basic information about your child.',
                    'We provide foundational literacy and numeracy programs guided by experienced teachers.',
                    'Engage your child with fun activities like storytelling and games for better participation.',
                  ].map((item, i) => (
                    <Grid item xs={12} key={i}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={item} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  K1
                </Typography>
                <Typography component={'p'}>
                  Foundation skills in literacy and numeracy. Phonics and sentence construction. Strokes and sequence in Chinese characters. Joy of language learning with friends. Storytelling and games sessions to motivate children’s participation and expression.
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  K2
                </Typography>
                <Typography component={'p'}>
                  Strengthening skills in listening, speaking, reading and writing. Word-problem solving skills to prepare students for Primary 1 Mathematics lessons. Chinese and English vocabulary enrichment. Basic Hanyu Pinyin skill-vowels, consonants. Storytelling and games sessions to motivate and encourage children’s participation for more effective learning.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={isMd ? 4 : 2} direction="column">
                <Grid item xs={12} data-aos="fade-up">
                  <Box component={Card} bgcolor={'primary.main'}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                        sx={{ color: 'common.white' }}
                      >
                        Join Us for a Bright Start!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ color: 'common.white' }}
                      >
                        <Box
                          component="img"
                          src="/tuition/P-Tuition-P00.jpg"
                          sx={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '500px',
                            objectFit: 'cover',
                          }}
                        />
                      </Typography>
                    </CardContent>
                  </Box>
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Box component={Card}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom color="text.primary">

                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href='/blog'
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        View More
                      </Button>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default PreSchool;
