import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from '../../components/Container';
import { Main } from '../../layouts';

const ArtClasses = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
  <Main>
    <Container>
      <Box>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box>
            <Typography fontWeight={700} variant={'h4'} gutterBottom>
              Art Classes
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginY: 4 }} />
        <Grid container spacing={isMd ? 4 : 2}>
          <Grid item xs={12} md={8}>
            <Box marginBottom={3}>
              <Typography variant={'h5'} fontWeight={700} gutterBottom>
                Description
              </Typography>
              <Typography component={'p'}>
                Benefits for children to learn painting - Stimulate children's imagination for them to appreciate the beauty of life and nature
              </Typography>
            </Box>
            <Box marginBottom={3}>
              <Grid container spacing={1} sx={{ marginTop: 1 }}>
                  {[
                      "Oil Painting: Express your creativity with vibrant colors and textures.",
                      "Watercolor: Master the delicate art of blending and soft hues.",
                      "Sketching: Develop your drawing skills and eye for detail.",
                      "Creative Arts: Unleash your imagination through diverse artistic techniques."
                  ].map((item, i) => (
                    <Grid item xs={12} key={i}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={item} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={isMd ? 4 : 2} direction="column">
              <Grid item xs={12} data-aos="fade-up">
                <Box component={Card} bgcolor={'primary.main'}>
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      color="text.primary"
                      sx={{ color: 'common.white' }}
                    >
                      Nurturing Young Minds Every Step of the Way!
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      sx={{ color: 'common.white', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                      <img 
                        src="/img/Art-Class-P01.png"
                        sx={{
                          width: '100%',
                          height: 'auto',
                          objectFit: 'cover',
                        }}
                      />
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
              <Grid item xs={12} data-aos="fade-up">
                <Box component={Card}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom color="text.primary">
    
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      href='./blog'
                      endIcon={
                        <Box
                          component={'svg'}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          width={24}
                          height={24}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 8l4 4m0 0l-4 4m4-4H3"
                          />
                        </Box>
                      }
                    >
                      View More
                    </Button>
                  </CardContent>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </Main>
  );
};

export default ArtClasses;
