import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth, Amplify } from 'aws-amplify';
import {
    Box,
    TextField,
    Button,
    Typography,
    Container,
    Alert,
    CircularProgress
} from '@mui/material';

Amplify.configure({
    Auth: {
        region: 'ap-southeast-1',
        userPoolId: 'ap-southeast-1_JBRlVohJz',
        userPoolWebClientId: 'j8rtg630nnm2174a8ahkd8r02',
        authenticationFlowType: 'USER_PASSWORD_AUTH',  // 使用用户名密码认证流
        cookieStorage: {
            // domain: 'localhost', // 设置为主域名
            domain: 'studentcare.sg', // 设置为主域名
            path: '/',
            expires: 365,
        },
    }
});

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            
            const user = await Auth.signIn(username, password);
            // if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            //     const defaultPassword = 'Default@123'; // 使用一个预定义的默认密码
            //     const updatedUser = await Auth.completeNewPassword(user, defaultPassword);
            //     console.log('Automatically completed NEW_PASSWORD_REQUIRED:', updatedUser);
            // }
            console.log('Login successful:', user);
            setTimeout(() => {
                navigate('/dashboard', { replace: true });
              }, 0);        
            } catch (error) {
            console.error('Login error:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Container maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>

                {error && (
                    <Alert severity="error" sx={{ mt: 2, width: '100%' }}>
                        {error}
                    </Alert>
                )}

                <Box component="form" onSubmit={handleLogin} sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default Login;
