const pages = {
  home: {
    title: 'Home',
    href: '/',
  },
  programme: [
    {
      title: 'Pre-School Tuition',
      href: '/pre-school',
    },
    {
      title: 'Primary School Tuition',
      href: '/primary-school',
    },
    {
      title: 'Secondary School Tuition',
      href: '/secondary-school',
    },
    {
      title: 'Art Classes for Children',
      href: '/art-classes',
    },
  ],
  // gallery: {
  //   title: 'Gallery',
  //   href: '/gallery',
  // },
  blog: {
    title: 'Blog',
    href: '/blog',
  },
  about: {
    title: 'About Us',
    href: '/about',
  },
};

export default pages;
