import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Story = () => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" align="center" fontWeight={700}>
            HappyCare - Our Philosophy and Approach
          </Typography>
            <br />
            <br />
          <Typography component={'p'} color={'text.secondary'} fontWeight={400}>
            All children will have a happy experience in HappyCare. (Bedok Tuition @HappyCare)
            Our secondary programme is specially designed by a team of experienced educators. Our programmes supplement the local school syllabus and help students of all levels to manage their school examinations. We constantly update our curriculum in order to keep abreast of New MOE guidelines and continually create new teaching and learning strategies.
            <br />
            <br />
            Bedok North student care @ HappyCare has been awarded Grade A funding qualification by MSF (Ministry of Social and Family Development) for the Lower Income Families Subsidy Scheme.
            The Centre (student care @ Bedok North), comprising two storeys of space, provides ample space of a minimum 3 m² floor area per person for students to interact, study, and relax.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
