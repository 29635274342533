import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from '../../layouts/Main';
import Container from '../../components/Container';
import { Contact } from './components';


const Dashboard = () => {
  const theme = useTheme();

  return (
    <Main>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          <Container>
            <Contact />
          </Container>
        </Box>
      </Box>
    </Main>
  );
};

export default Dashboard;