import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { ImageList, ImageListItem, CircularProgress, IconButton } from '@mui/material';
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
// import Slide from '@mui/material/Slide';
import { Button, Snackbar, Alert } from '@mui/material';

const s3Client = new S3Client({
  region: 'ap-southeast-1',
  credentials: {
    accessKeyId: 'AKIASK5MCRVB66EOLAE3',
    secretAccessKey: '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'
  }
});

const lambdaClient = new LambdaClient({
  region: "ap-southeast-1",
  credentials: {
    accessKeyId: "AKIASK5MCRVB66EOLAE3",
    secretAccessKey: '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'
  }
});

const BUCKET_NAME = 'happycareblog';
const S3_BASE_URL = `https://${BUCKET_NAME}.s3.ap-southeast-1.amazonaws.com`;

const validationSchema = yup.object({
  title: yup.string().trim().required('Please specify your title'),
  // content: yup.string().trim().required('Please specify your content'),
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Contact = () => {
  const [itemData, setItemData] = useState([]);
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState({});
  const [open, setOpen] = useState(false);

  // 打开 Snackbar
  const handleClick = () => {
    setOpen(true);
  };

  // 关闭 Snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const theme = useTheme();

  const uploadFile = async ({ file, fileName }) => {
    if (!file || !fileName) {
      throw new Error('File and fileName are required');
    }

    let fileContent = await file.arrayBuffer().then(buf => new Uint8Array(buf));

    const command = new PutObjectCommand({
      Bucket: BUCKET_NAME,
      Key: fileName,
      Body: fileContent,
      ContentType: file.type
    });

    try {
      const response = await s3Client.send(command);
      console.log('Upload successful:', response);
      return {
        ...response,
        Key: fileName,
        Location: `${S3_BASE_URL}/${fileName}`
      };
    } catch (error) {
      console.error('Upload error:', error);
      throw error;
    }
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;

    setError('');

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileId = `${Date.now()}-${i}`;

      // 文件验证
      if (!file.type.startsWith('image/')) {
        setError('Only image files are allowed.');
        continue;
      }
      if (file.size > 5000000) {
        setError('File size must be less than 5MB.');
        continue;
      }

      // 设置上传状态
      setUploading(prev => ({
        ...prev,
        [fileId]: { progress: 0, fileName: file.name }
      }));

      try {
        // 创建预览
        const reader = new FileReader();
        reader.onloadend = () => {
          setItemData(prev => [...prev, {
            id: fileId,
            img: reader.result,
            title: file.name,
            uploading: true
          }]);
        };
        reader.readAsDataURL(file);

        // 上传文件
        const res = await uploadFile({
          file: file,
          fileName: `images/${fileId}-${file.name}` // 添加路径前缀和唯一标识符
        });

        // 更新上传完成的状态
        setItemData(prev => prev.map(item =>
          item.id === fileId
            ? {
              ...item,
              uploading: false,
              url: `${S3_BASE_URL}/images/${fileId}-${file.name}`,
              key: `images/${fileId}-${file.name}`
            }
            : item
        ));

        setUploading(prev => {
          const newState = { ...prev };
          delete newState[fileId];
          return newState;
        });

      } catch (err) {
        setError(`Failed to upload ${file.name}: ${err.message}`);
        console.error('Upload error:', err);

        // 移除失败的文件
        setItemData(prev => prev.filter(item => item.id !== fileId));
        setUploading(prev => {
          const newState = { ...prev };
          delete newState[fileId];
          return newState;
        });
      }
    }
  };

  const handleDeleteImage = (id) => {
    setItemData(prev => prev.filter(item => item.id !== id));
  };

  const initialValues = {
    title: '',
  };

  const onSubmit = async (values) => {
    if (itemData.length === 0) {
      setError('Please upload at least one image before submitting');
      return;
    }

    try {
      const formData = {
        title: values.title,
        images: itemData.map(item => item.url),
      };

      try {
        // 准备调用参数
        const command = new InvokeCommand({
          FunctionName: "savePost", // 替换为你的 Lambda 函数名
          InvocationType: "RequestResponse",
          Payload: JSON.stringify(formData)
        });

        // 调用 Lambda
        const response = await lambdaClient.send(command);


        // 处理响应
        if (response.StatusCode !== 200) {
          throw new Error(`Lambda invocation failed with status ${response.StatusCode}`);
        }

        // 解析响应数据
        const result = JSON.parse(new TextDecoder().decode(response.Payload));

        if (result.statusCode !== 200) {
          setError('Submission failed. Please try again.');
          return;
        }

        // 重置表单
        formik.resetForm();
        setItemData([]);
        setError('');
        handleClick();

        return JSON.parse(result.body);

      } catch (error) {
        console.error('Lambda invocation error:', error);
      }

    } catch (err) {
      setError('Error submitting form. Please try again.');
      console.error('Submit error:', err);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <Box maxWidth={400} margin={'0 auto'}>
            <Card sx={{ backgroundImage: 'none', boxShadow: 4 }}>
              <CardMedia
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  component={'svg'}
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 1920 100.1"
                  sx={{
                    width: '100%',
                    bottom: 0,
                    position: 'absolute',
                  }}
                >
                  <path
                    fill={theme.palette.background.default}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                  ></path>
                </Box>
              </CardMedia>
              <CardContent>
                <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Title"
                        name="title"
                        fullWidth
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                      />
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        label="Content"
                        name="content"
                        fullWidth
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        error={formik.touched.content && Boolean(formik.errors.content)}
                        helperText={formik.touched.content && formik.errors.content}
                        multiline
                        rows={15}
                      />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        component="label"
                        variant="contained"
                        fullWidth
                        startIcon={<CloudUploadIcon />}
                        disabled={Object.keys(uploading).length > 0}
                      >
                        Upload files
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange}
                          accept="image/*"
                          multiple
                          disabled={Object.keys(uploading).length > 0}
                        />
                      </Button>
                    </Grid>
                    {error && (
                      <Grid item xs={12}>
                        <Box sx={{ color: 'error.main', mt: 1 }}>
                          {error}
                        </Box>
                      </Grid>
                    )}
                    {Object.keys(uploading).map(fileId => (
                      <Grid item xs={12} key={fileId}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <CircularProgress size={20} />
                          <Box sx={{ flexGrow: 1 }}>
                            {`Uploading ${uploading[fileId].fileName}...`}
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        type="submit"
                        disabled={Object.keys(uploading).length > 0}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          xs={12}
          md={6}
          data-aos={'fade-up'}
        >
          <Box>
            <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
              {itemData.map((item) => (
                <ImageListItem key={item.id} sx={{ position: 'relative' }}>
                  <img
                    src={item.img}
                    alt={item.title}
                    loading="lazy"
                  />
                  {item.uploading && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'rgba(0, 0, 0, 0.5)',
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 5,
                      right: 5,
                      bgcolor: 'background.paper',
                      '&:hover': {
                        bgcolor: 'background.paper',
                      },
                    }}
                    size="small"
                    onClick={() => handleDeleteImage(item.id)}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
          <Snackbar
            open={open}
            autoHideDuration={3000}  // 自动消失时间（3秒）
            onClose={handleClose}
            // TransitionComponent={(props) => <Slide {...props} direction="down" />}  // 使用 Slide 动画
          >
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Upload Successful!
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;