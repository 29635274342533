import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from '../../components/Container';
import { Main } from '../../layouts';

const PrimarySchool = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
        <Box>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
          >
            <Box>
              <Typography fontWeight={700} variant={'h4'} gutterBottom>
                Primary School Tuition
              </Typography>
            </Box>
          </Box>
          <Divider sx={{ marginY: 4 }} />
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} md={8}>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Description
                </Typography>
                <Typography component={'p'}>
                  Our tuition centre offers the following subjects for primary school students:
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Box
                  component={'img'}
                  loading="lazy"
                  height={1}
                  width={1}
                  src={'/img/Schedule.png'}
                  alt="..."
                  boxShadow={3}
                  borderRadius={2}
                  maxWidth={600}
                  sx={{
                    mx: 'auto',  // 水平居中
                    display: 'block',  // 确保图片作为块级元素
                    filter: (theme) => theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  }}
                />
                <Grid container spacing={1} sx={{ marginTop: 1 }}>
                  {[
                    "English: Enhance language skills for effective communication.",
                    "Mathematics: Build a strong foundation in mathematical concepts and problem-solving.",
                    "Chinese: Master Chinese language proficiency in reading, writing, and speaking.",
                    "Science (P3-P6): Explore scientific concepts and spark curiosity for discovery."
                  ].map((item, i) => (
                    <Grid item xs={12} key={i}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width={'auto'}
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth={'auto !important'}
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={theme.palette.secondary.main}
                            width={20}
                            height={20}
                          >
                            <svg
                              width={12}
                              height={12}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={item} />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Quality Teaching Materials
                </Typography>
                <Typography component={'p'}>
                  Our programme is based on MOE’s latest syllabus and examination formats.
                  Our teaching materials are specially designed to include skill-based modules, targeted practices and exciting activities, tailored for all students. The process is aiming to help students improve in their class performance and enhance students’ self confidence to create a love for learning.
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Conducive Learning Environment
                </Typography>
                <Typography component={'p'}>
                  Small class-size lessons are conducted with no more than 8 students per class for better individual attention and focus.
                </Typography>
              </Box>
              <Box marginBottom={3}>
                <Typography variant={'h5'} fontWeight={700} gutterBottom>
                  Quality Teaching
                </Typography>
                <Typography component={'p'}>
                  Experienced teachers graduated from local or overseas universities with many years of relevant teaching experience proficient to guide students absorb what they have learnt.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container spacing={isMd ? 4 : 2} direction="column">
                <Grid item xs={12} data-aos="fade-up" >
                  <Box component={Card} bgcolor={'primary.main'}>
                    <CardContent>
                      <Typography
                        variant="h6"
                        gutterBottom
                        color="text.primary"
                        sx={{ color: 'common.white' }}
                      >
                        Discover the Joy of Learning Together!
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        sx={{ color: 'common.white' }}
                      >
                        <Box
                          component="img"
                          src="/tuition/K-Tuition-P00.jpg"
                          sx={{
                            width: '100%',
                            height: 'auto',
                            maxWidth: '500px',
                            objectFit: 'cover',
                          }}
                        />
                      </Typography>
                    </CardContent>
                  </Box>
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Box component={Card}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom color="text.primary">

                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        href='/blog'
                        endIcon={
                          <Box
                            component={'svg'}
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            width={24}
                            height={24}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17 8l4 4m0 0l-4 4m4-4H3"
                            />
                          </Box>
                        }
                      >
                        View More
                      </Button>
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Main>
  );
};

export default PrimarySchool;
