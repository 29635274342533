import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@mui/material/Zoom';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// 转换API数据格式以匹配原有的mock数据结构
const transformApiData = (posts) => {
  return posts.map(post => ({
    images: post.images,
    image: post.images[0],
    description: post.content,
    title: post.title,
    author: {
      name: 'HappyCare',
      avatar: '/img/Logo.png', // 使用默认头像
    },
    date: new Date(post.createdAt * 1000).toLocaleDateString('en-US', {
      day: '2-digit',
      month: 'short'
    })
  }));
};

const Result = () => {
  const theme = useTheme();
  const [allArticles, setAllArticles] = useState([]); // 存储所有文章
  const [displayedArticles, setDisplayedArticles] = useState([]); // 存储当前显示的文章
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const itemsPerRow = 3; // 每行显示的数量
  const initialRows = 2; // 初始显示的行数
  const loadMoreRows = 2; // 每次加载的行数

  // 获取所有数据
  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await fetch('https://zobhgnrc23mqagiwgv6uwtqz5q0mtwrf.lambda-url.ap-southeast-1.on.aws/');
        const data = await response.json();
        const transformedData = transformApiData(data.posts);
        setAllArticles(transformedData);

        // 设置初始显示的文章
        const initialDisplay = transformedData.slice(0, itemsPerRow * initialRows);
        setDisplayedArticles(initialDisplay);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenDialog(true);
  };

  // 处理加载更多
  const handleLoadMore = () => {
    const currentLength = displayedArticles.length;
    const nextBatch = allArticles.slice(
      currentLength,
      currentLength + (itemsPerRow * loadMoreRows)
    );
    setDisplayedArticles(prev => [...prev, ...nextBatch]);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '400px' // 设置最小高度，避免加载时页面抖动
        }}
      >
        <CircularProgress
          size={40}
          thickness={4}
          sx={{
            color: theme.palette.primary.main
          }}
        />
      </Box>
    );
  }

  // 检查是否还有更多文章可以加载
  const hasMore = displayedArticles.length < allArticles.length;

  return (
    <Box>
      <Grid container spacing={4}>
        {displayedArticles.map((item, i) => (
          <Grid item xs={12} sm={6} md={4} key={i}>
            <Box
              component={'a'}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                boxShadow={4}
                display={'flex'}
                flexDirection={'column'}
                sx={{ backgroundImage: 'none' }}
              >
                <CardMedia
                  component="img"
                  image={item.image}
                  title={item.title}
                  sx={{
                    height: { xs: 300, md: 360 },
                    objectFit: 'cover',
                    cursor: 'pointer', // Make cursor a pointer when hovering over the image
                  }}
                  loading="lazy" // 添加懒加载
                  onClick={() => handleImageClick(item.images)} // Add onClick handler
                />
                <Box component={CardContent} position={'relative'}>
                  <Typography variant={'h6'} gutterBottom>
                    {item.title}
                  </Typography>
                  {/* <Typography color="text.secondary">
                    {item.description}
                  </Typography> */}
                </Box>
                <Box flexGrow={1} />
                <Box padding={2} display={'flex'} flexDirection={'column'}>
                  <Box marginBottom={2}>
                    <Divider />
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar
                        src={item.author.avatar}
                        sx={{ marginRight: 1 }}
                      />
                      <Typography color={'text.secondary'}>
                        {item.author.name}
                      </Typography>
                    </Box>
                    <Typography color={'text.secondary'}>
                      {item.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}

        {hasMore && (
          <Grid item container justifyContent={'center'} xs={12}>
            <Button
              fullWidth
              variant={'outlined'}
              size={'large'}
              onClick={handleLoadMore}
              sx={{ height: 54, maxWidth: 400, justifyContent: 'space-between' }}
              endIcon={
                <Box
                  component={'svg'}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  width={24}
                  height={24}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </Box>
              }
            >
              Load more
            </Button>
          </Grid>
        )}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        TransitionComponent={Zoom}
      >
        <DialogContent sx={{ position: 'relative', padding: 0 }}>
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {selectedImage && (
            <>
              {(() => {
                if (selectedImage.length > 1) {
                  console.log(selectedImage);
                  return (
                    <ImageList
                      cols={1}
                      gap={8}
                      sx={{
                        width: '100%',
                        height: 'auto',
                      }}
                    >
                      {selectedImage.map((img, idx) => (
                        <ImageListItem key={idx}>
                          <img
                            src={img}
                            alt={`Image ${idx + 1}`}
                            loading="lazy"
                            style={{ width: '100%', height: 'auto' }}
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  );
                } else {
                  console.log(selectedImage);

                  return (
                    <Box
                      component="img"
                      src={selectedImage}
                      alt="Selected"
                      sx={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                  );
                }
              })()}
            </>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default Result;