import React from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const mock = [
  {
    title: 'Pre-School Tuition',
    subtitle:
      'Our kindergarten tuition programmes focus on building a strong foundation in languages and mathematics for the young kids.',
    icon: '/tuition/P-Tuition-P00.jpg',
    href: '/pre-school',
  },
  {
    title: 'Primary School Tuition',
    subtitle:    
      'Our programme is based on MOE’s latest syllabus and examination formats. Our teaching materials are specially designed to include skill-based modules, targeted practices and exciting activities, tailored for all students.',
    icon: '/tuition/K-Tuition-P00.jpg',
    href: '/primary-school',
  },
  {
    title: 'Secondary School Tuition',
    subtitle:
      'Our secondary school student tuition programmes are carefully designed by a team of experienced educators tailored for all students.',
    icon: '/img/Second-Tuition-p00.png',
    href: '/secondary-school',
  },
  {
    title: 'Art Classes for Children',
    subtitle:
      'Benefits for children to learn painting - Stimulate children\'s imagination for them to appreciate the beauty of life and nature',
    icon: '/img/Art-Class-P01.png',
    href: '/art-classes',
  },
];

const Reviews = () => {
  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={6} key={i}>
            <Box
              component={'a'}
              href={item.href}
              display={'block'}
              width={1}
              height={1}
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={1}
                height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
                data-aos-offset={100}
                data-aos-duration={600}
                flexDirection={'column'}
                display={'flex'}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"  // 水平居中
                    alignItems="center"      // 垂直居中
                    component={Avatar}
                    width={{ xs: 60, md: 80 }}
                    height={{ xs: 60, md: 80 }}
                    marginBottom={2}
                    src={item.icon}
                  />
                  <Typography
                    variant={'h6'}
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    {item.title}
                  </Typography>
                  <Typography color="text.secondary">
                    {item.subtitle}
                  </Typography>
                </CardContent>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Reviews;
