import React from 'react';

import {
  Dashboard as Dashboard,
} from './';

const protectedRoutes = [
  {
    path: '/dashboard',
    renderer: (params = {}) => <Dashboard {...params} />,
  },
];

export default protectedRoutes;