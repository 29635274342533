// import React, { useState } from 'react';
import React from 'react';
// import CountUp from 'react-countup';
// import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

// const mock = [
//   {
//     title: 120,
//     subtitle:
//       'Managed students',
//     suffix: '+',
//   },
//   {
//     title: 15,
//     subtitle:
//       'Certified teacheres',
//     suffix: '+',
//   },
//   {
//     title: 38,
//     subtitle: 'Courses offered',
//     suffix: '+',
//   },
// ];

const Hero = () => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  // const [viewPortEntered, setViewPortEntered] = useState(false);
  // const setViewPortVisibility = (isVisible) => {
  //   if (viewPortEntered) {
  //     return;
  //   }

  //   setViewPortEntered(isVisible);
  // };

  return (
    <Grid container spacing={4}>
      <Grid
        item
        container
        alignItems={'center'}
        justifyContent={'center'}
        xs={12}
        md={6}
        data-aos="flip-left"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="2000"
      >
        <Box
          component={'img'}
          loading="lazy"
          height={1}
          width={1}
          src={'/gallery/File 9.jpg'}
          alt="..."
          boxShadow={3}
          borderRadius={2}
          maxWidth={600}
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
          }}
        />
      </Grid>
      <Grid item container xs={12} md={6} alignItems={'center'}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{ fontWeight: 700 }}
            >
              HappyCare provides a good learning environment for students and help them complete their homework {' '}
              <Typography
                color={'primary'}
                component={'span'}
                variant={'inherit'}
              >
                before going home.
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={4}>
            <Typography variant="h6" component="p" color="text.secondary">
            </Typography>
          </Box>
          {/* <Box>
            <Grid container spacing={2}>
              {mock.map((item, i) => (
                <Grid key={i} item xs={12} md={4}>
                  <Typography variant="h3" gutterBottom>
                    <Box fontWeight={600}>
                      <VisibilitySensor
                        onChange={(isVisible) =>
                          setViewPortVisibility(isVisible)
                        }
                        delayedCall
                      >
                        <CountUp
                          duration={2}
                          end={viewPortEntered ? item.title : 0}
                          start={0}
                          suffix={item.suffix}
                        />
                      </VisibilitySensor>
                    </Box>
                  </Typography>
                  <Typography color="text.secondary" component="p">
                    {item.subtitle}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Box> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
