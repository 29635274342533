import React from 'react';

import {
  Home as Home,
  PreSchool as PreSchool,
  PrimarySchool as PrimarySchool,
  SecondarySchool as SecondarySchool,
  ArtClasses as ArtClasses,
  ContactPage as ContactPageView,
  // Gallery as Gallery,
  AboutSideCover as AboutSideCoverView,
  BlogSearch as BlogSearchView,
  NotFound as NotFoundView,
  Login as Login,
  Highlights as HighlightsView,
} from './';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <Home {...params} />,
  },
  {
    path: '/index',
    renderer: (params = {}) => <Home {...params} />,
  },
  {
    path: '/pre-school',
    renderer: (params = {}) => <PreSchool {...params} />,
  },
  {
    path: '/primary-school',
    renderer: (params = {}) => <PrimarySchool {...params} />,
  },
  {
    path: '/secondary-school',
    renderer: (params = {}) => <SecondarySchool {...params} />,
  },
  {
    path: '/art-classes',
    renderer: (params = {}) => <ArtClasses {...params} />,
  },
  {
    path: '/contact',
    renderer: (params = {}) => <ContactPageView {...params} />,
  },
  // {
  //   path: '/gallery',
  //   renderer: (params = {}) => <Gallery {...params} />,
  // },
  {
    path: '/highlights',
    renderer: (params = {}) => <HighlightsView {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}) => <AboutSideCoverView {...params} />,
  },
  {
    path: '/blog',
    renderer: (params = {}) => <BlogSearchView {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}) => <NotFoundView {...params} />,
  },
  {
    path: '/login',
    renderer: (params = {}) => <Login {...params} />,
  },
];

export default routes;