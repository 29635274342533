import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Item = ({ title, id, item, colorInvert = false }) => {
  const theme = useTheme();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);

  const linkColor = colorInvert ? 'common.white' : 'text.primary';

  const hasActiveLink = () => item.href === activeLink

  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        aria-describedby={id}
        sx={{ cursor: 'pointer' }}
      >
        <Typography
          component={'a'}
          href={item.href}
          fontWeight={hasActiveLink() ? 700 : 400}
          color={linkColor}
          sx={{
            textDecoration: 'none',
          }}
        >
          {item.title}
          </Typography>
      </Box>
    </Box>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default Item;
