import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import viewsRoutes from './views/routes';
import protectedRoutes from './views/protectedRoutes';
import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Backdrop, CircularProgress } from '@mui/material';

const Routes = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await Auth.currentSession();
        if (session.isValid()) {
          console.log('Session is valid');
          setIsAuthenticated(true);
        } else {
          console.log('Session is invalid');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.log('Session check failed:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkSession();
  }, []);

  if (isLoading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <ReactRoutes>
      {viewsRoutes.map((item, i) => (
        <Route key={i} path={item.path} element={item.renderer()} />
      ))}
      {protectedRoutes.map((item, i) => (
        <Route
          key={i}
          path={item.path}
          element={isAuthenticated ? item.renderer() : <Navigate replace to="/login" />}
        />
      ))}
      <Route path="*" element={<Navigate replace to="/not-found" />} />
    </ReactRoutes>
  );
};

export default Routes;