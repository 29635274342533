import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

import { NavItem, Item } from './components';

const SidebarNav = ({ pages }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const {
    home: homePages,
    programme: programmePages,
    // gallery: galleryPages,
    blog: blogPages,
    about: aboutPages,
  } = pages;

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? '/img/Logo.png'
                : '/img/Logo.png'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <Item
            title={'Home'}
            id={'home-pages'}
            item={homePages}
          />        </Box>
        <Box>
          <NavItem
            title={'Programme'}
            id={'programme-pages'}
            items={programmePages}
          />        </Box>
        <Box>
          <Item
            title={'Blog'}
            id={'blog-pages'}
            item={blogPages}
          />        </Box>
        <Box>
          <Item
            title={'About'}
            id={'about-pages'}
            item={aboutPages}
          />        </Box>
        <Box marginTop={1}>
          <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="/contact"
            size="large"
          >
            Contact
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
};

export default SidebarNav;
