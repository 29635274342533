import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import CardActionArea from '@mui/material/CardActionArea';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// Importing icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const mock = [
  {
    name: 'Mdm Tang',
    title: 'Principal & Director',
    avatar: '/team/1751737626027_.pic.jpg',
    about: [
      'Graduated from Huazhong Normal University.',
      'Bachelor Degree in Chinese Language and Literature.',
      'Over 30 years of teaching experiences in both Singapore and China.',
      'Participated in an Exchange Program with an elementary school in California, USA in 2007 and awarded Honorary Elementary School Educator status.',
      'Executive director & Vice Principal at Han Language Centre (Tampines) Pte. Ltd. from 2014 to 2018.',
      'Ex-Senior Teacher in a key school in China.',
      'Wrote and published several language teaching and enrichment materials.',
      'Helped many students achieve excellent results in PSLE and O’Level examinations, especially in composition writing.',
    ],
  },
  {
    name: 'Mdm Ho',
    title: 'Supervisor',
    avatar: '/team/1761737626028_.pic.jpg',
    about: [
      'Graduated from National Chung Hsing University, Taiwan.',
      'Bachelor Degree in Chinese Literature.',
      '5 years of experience as a kindergarten teacher.',
      'Several years as a daycare teacher.',
      'Over 10 years of teaching experience in Chinese.',
      'Organized teaching activities and trained students for competitions such as poetry and speech contests.',
      'Engaging teaching style, well-liked by students and receives compliments from parents.',
    ],
  },
  {
    name: 'Teacher Mandy',
    title: 'Teacher',
    avatar: '/team/1771737699605_.pic.jpg',
    about: [
      "G.C.E 'A' Level graduate from Bartley Secondary School.",
      '13 years of full-time home tuition experience, both one-to-one and small groups.',
      'Focuses on assessment guidance for all primary levels.',
      'Successfully taught many PSLE students who attained at least 3 subjects with A* grades.',
      'Always dedicated and committed to helping students improve and achieve good results.',
    ],
  },
  {
    name: 'Mr. Adwin',
    title: 'Teacher',
    avatar: '/team/Mr-Adwin-259x308-1.png',
    about: [
      'Graduated from Murdoch University with straight distinctions.',
      '14 years of teaching experience across the Primary and Secondary levels.',
      'Track record of producing distinctions in English and Mathematics.',
      'Familiar with both MOE and IB syllabi.',
      'Developed proprietary materials focused on progressive learning of English and Mathematics.',
      'Interactive teaching style incorporating traditional methods and technology to enhance learning.',
    ],
  },
  {
    name: 'Miss Ren',
    title: 'Teacher',
    avatar: '/team/1631737620131_.pic.jpg',
    about: [
      'Graduated from National University of Singapore.',
      'Bachelor Degree in Engineering.',
      'Skilled in teaching Science and Mathematics.',
      '10 years of teaching experience.',
      'Uses flexible and diverse teaching methods, incorporating innovative techniques to engage students.',
      'Ensures students achieve their academic potential and develop a deep understanding of the subjects.',
    ],
  },
  {
    name: 'Mdm Dong',
    title: 'Teacher',
    avatar: '/team/Mdm-Dong.png',
    about: [
      'Graduated from National University of Australia.',
      'Master in Finance and Economics.',
      'Worked in the education sector in Singapore since 1996 and is a senior teacher at Han Language Centre.',
      'Highly dedicated and passionate teacher, well respected and liked by her students.',
      '30 years of tutoring experience in Chinese for PSLE & O Level.',
    ],
  },
  {
    name: 'Miss Janelle',
    title: 'Teacher',
    avatar: '/team/1531737618001_.pic.jpg',
    about: [
      'Graduated from Singapore Management University.',
      'Bachelor Degree in Psychology.',
      'Several years of experience working with children aged 6-12 years old.',
      'Her teaching expertise extends to guiding students in the English language, where she creates engaging and effective lessons tailored to their individual strengths.',
      'Her educational background in psychology enriches her teaching approach, allowing her to connect with students on a deeper level, foster their confidence, and support their growth both academically and personally.',
    ],
  },
  {
    name: 'Miss Sharifah',
    title: 'Teacher',
    avatar: '/team/1511737617898_.pic.jpg',
    about: [
      'Graduated from National University of Singapore.',
      'Several years of teaching experience of Primary and Secondary students on a one-to-one basis.',
      'Cultivated a strong ability to provide focused and individualized attention to her students.',
      'Familiar with MOE syllabus, aligning her lessons with current educational standards and requirements.',
      "Committed to understanding each student's unique learning style, designing interactive and personalized teaching methods.",
    ],
  },
];

const Team = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color="text.secondary"
          align="center"
        >
          Our Team
        </Typography>
        <Typography fontWeight={700} variant="h4" align="center">
          Trust the Professionals
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
                transition: 'transform 0.2s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                },
              }}
            >
              <CardActionArea
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <CardContent
                  sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start', // Align content to the top
                    textAlign: 'center',
                  }}
                >
                  <Avatar
                    alt={item.name}
                    src={item.avatar}
                    sx={{ width: 100, height: 100, margin: '0 auto' }}
                  />
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    marginTop={2}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    {item.title}
                  </Typography>
                  {item.about && item.about.length > 0 && (
                    <List
                      sx={{
                        marginTop: 1,
                        textAlign: 'left',
                        display: 'inline-block',
                      }}
                    >
                      {item.about.map((point, index) => (
                        <ListItem key={index} disableGutters>
                          <ListItemIcon sx={{ minWidth: 32 }}>
                            <CheckCircleIcon
                              fontSize="small"
                              color="primary"
                            />
                          </ListItemIcon>
                          <ListItemText primary={point} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                  {/* Flex spacer to push content to the top */}
                  <Box sx={{ flexGrow: 1 }} />
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;